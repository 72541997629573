import React from "react";
import emailjs from "emailjs-com";
import { Form, Input, TextArea } from "semantic-ui-react";
import Swal from "sweetalert2";

const SERVICE_ID = "service_e95itb3";
const TEMPLATE_ID = "template_w2yk56l";
const USER_ID = "SqPLX_yZBQRndzUy3";

const Contactform = () => {
  const handleOnSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID).then(
      (result) => {
        console.log(result.text);
        Swal.fire({
          icon: "success",
          title: "Message Sent Successfully",
        });
      },
      (error) => {
        console.log(error.text);
        Swal.fire({
          icon: "error",
          title: "Ooops, something went wrong",
          text: error.text,
        });
      }
    );
    e.target.reset();
  };

  return (
    <div className="contactform">
      <Form onSubmit={handleOnSubmit}>
        <div className="labelInput">E-Mail Adresse</div>
        <input
          className="formField"
          id="form_email"
          control={Input}
          name="user_email"
          placeholder="Email…"
          required
          icon="mail"
          iconPosition="left"
          style={{
            height: "50px",
            borderRadius: "5px",
            marginBottom: "10px",
            padding: "10px",
            backgroundColor: "#F5F5F5",
          }}
        />
        <div className="labelInput">Name</div>
        <input
          className="formField"
          id="form_name"
          control={Input}
          name="user_name"
          placeholder="Name…"
          required
          icon="user circle"
          iconPosition="left"
          style={{
            height: "50px",
            borderRadius: "5px",
            marginBottom: "10px",
            padding: "10px",
            backgroundColor: "#F5F5F5",
          }}
        />
        <div className="labelInput">Nachricht</div>
        <textarea
          className="formField"
          id="message"
          control={TextArea}
          name="user_message"
          placeholder="Message…"
          required
          style={{
            borderRadius: "5px",
            marginBottom: "10px",
            padding: "10px",
            resize: "vertical",
            minHeight: "150px",
            backgroundColor: "#F5F5F5",
          }}
        />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button className="submit-button" type="submit">
            Submit
          </button>
        </div>
      </Form>
    </div>
  );
};

export default Contactform;
