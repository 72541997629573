export const Datenschutz = () => {
  return (
    <>
      <div className="datenschutz_Container">
        <h1>Datenschutzbestimmungen</h1>
        <p>
          Wir bei Oracode legen großen Wert auf den Schutz Ihrer persönlichen
          Daten. In diesem Dokument möchten wir Ihnen erläutern, welche
          Informationen wir sammeln, wie wir sie nutzen und welche Maßnahmen wir
          ergreifen, um Ihre Daten zu schützen. Bitte nehmen Sie sich einen
          Moment Zeit, um unsere Datenschutzbestimmungen zu lesen.
        </p>
        <h3>Erfassung und Nutzung von Informationen:</h3>
        <p>
          <h5>a.</h5> Personenbezogene Daten: Wir erfassen nur die Daten, die
          Sie freiwillig zur Verfügung stellen, z. B. Name, E-Mail-Adresse und
          Kontaktdaten. Diese Informationen werden nur zur Kommunikation mit
          Ihnen verwendet und nicht an Dritte weitergegeben, sofern nicht
          ausdrücklich angegeben oder gesetzlich vorgeschrieben.
        </p>
        <p>
          <h5>b.</h5> Protokolldateien: Wie viele andere Websites erfassen wir
          automatisch bestimmte Informationen, wenn Sie unsere Website besuchen.
          Dies kann Ihre IP-Adresse, Browsertyp, Verweis-URLs, besuchte Seiten
          und andere Informationen umfassen. Diese Daten werden anonymisiert
          verwendet, um unsere Website zu analysieren und zu verbessern.
        </p>
        <h3>Verwendung von Cookies:</h3>
        <p>
          <h5>a.</h5> Wir verwenden Cookies, um bestimmte Informationen zu
          erfassen und Ihre Präferenzen zu speichern. Sie haben die Möglichkeit,
          Cookies abzulehnen oder zu löschen, indem Sie Ihre
          Browsereinstellungen entsprechend anpassen. Beachten Sie jedoch, dass
          dies möglicherweise die Funktionalität unserer Website beeinträchtigt.
        </p>
        <h3>Datensicherheit:</h3>
        <p>
          <h5> a.</h5> Wir setzen angemessene technische und organisatorische
          Maßnahmen ein, um Ihre Daten vor Verlust, Missbrauch, unbefugtem
          Zugriff oder Offenlegung zu schützen. Unsere Mitarbeiter sind zur
          Vertraulichkeit verpflichtet und wir verwenden Sicherheitsprotokolle,
          um den Zugriff auf unsere Systeme zu kontrollieren.
        </p>
        <h3>Dritte:</h3>
        <p>
          <h5>a.</h5> Wir teilen Ihre persönlichen Daten nicht ohne Ihre
          Zustimmung mit Dritten, sofern dies nicht zur Erfüllung Ihrer
          Anforderungen oder gesetzlich erforderlich ist.
        </p>
        <h3>Externe Links:</h3>
        <p>
          <h5>a.</h5> Unsere Website kann Links zu externen Websites enthalten,
          für deren Datenschutzpraktiken wir nicht verantwortlich sind. Bitte
          lesen Sie die Datenschutzerklärungen dieser Websites, bevor Sie
          persönliche Informationen eingeben.
        </p>
        <h3>Aktualisierungen dieser Datenschutzbestimmungen:</h3>
        <p>
          <h5>a.</h5> Wir behalten uns das Recht vor, diese
          Datenschutzbestimmungen von Zeit zu Zeit zu aktualisieren. Jegliche
          Änderungen werden auf unserer Website veröffentlicht. Durch die
          fortgesetzte Nutzung unserer Website nach der Veröffentlichung solcher
          Änderungen erklären Sie sich mit den aktualisierten
          Datenschutzbestimmungen einverstanden.
        </p>
        <p>
          Wenn Sie Fragen oder Bedenken bezüglich unserer Datenschutzpraktiken
          haben, wenden Sie sich bitte an uns. Wir stehen Ihnen gerne zur
          Verfügung, um Ihre Anliegen zu klären.
        </p>
        <p></p>
        <p></p>
      </div>
    </>
  );
};
