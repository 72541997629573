export default function WerSindWirWidget() {
  const currentPath = window.location.pathname;
  const isEnglish = currentPath.includes("/english");
  return (
    <a href="/WerSindWir">
      <div className="WerSindWirWidget">
        <h1 className="WerSindWirWidgetÜberschrift">
          {isEnglish ? "The Founders" : "Die Gründer"}
        </h1>
        <p className="WerSindWirWidgetText">
          {isEnglish
            ? "We, the founders Tom and Luke of Oracode, have been friends since third grade..."
            : "Wir die Gründer, Tom und Luke, von Oracode sind schon seid der dritten Klasse befreundet..."}
        </p>
        <p className="WerSindWirWidgetFotter">
          {isEnglish
            ? "Here you can find our story"
            : "Hier finden Sie unsere Geschichte"}
        </p>
      </div>
    </a>
  );
}
