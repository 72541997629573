export const englishText = [
  {
    id: 12,
    text: "Philosophy",
  },
  {
    id: 13,
    text: [
      "Our goal is to bring design and ",
      <span className="after">software</span>,
      "into harmony",
    ],
  },
  {
    id: 999,
    text: "",
  },
  {
    id: 14,
    text: "Our services",
  },
  {
    id: 15,
    text: [
      " •  Web-Hosting",
      <br />,
      " •  Web-Design",
      <br />,
      " •  Web-Development",
      <br />,
      " •  Graphic-Design",
    ],
  },
  {
    id: 16,
    text: "Why Oracode?",
  },
  {
    id: 17,
    text: [
      'The name Oracode is composed of "Ora" from ',
      <span className="after">Oracle</span>,
      " which symbolises wisdom and ",
      <span className="after">knowledge</span>,
      'and "code" for programming',
    ],
  },
  {
    id: 998,
    text: "",
  },
  {
    id: 18,
    text: "About Us",
  },
  {
    id: 19,
    text: [
      "We are Tom and Luke, the founders of",
      <span className="after"> Oracode </span>,
      "Our mission is to connect Design and Software in ",
      <span className="after"> Harmony.</span>,
      "With our passion for ",
      <span className="after">Technology,</span>,
      "we develop innovative Solutions that ",
      <span className="after">are</span>,
      "Functional and Aesthetically ",
      <span className="after">pleasing.</span>,
      "Let us turn Your Vision into Reality.",
    ],
  },
  {
    id: 120,
    text: "We work with",
  },
];
