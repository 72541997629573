export default function PlaceHolder() {
  return (
    <div className="PlaceHolderPage">
      <div className="PlaceHolder">
        <p className="PlaceHolderText">
          An disem Element arbeiten
          <br /> wir im Moment
        </p>
        <a href="/">
          <button className="PlaceHolderButton">Zurück zur Webseite</button>
        </a>
      </div>
    </div>
  );
}
