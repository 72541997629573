import WeeklyChalangeWidget from "./weeklyChalangeWidget";
import WerSindWirWidget from "./WerSindWirWidget";
import Contactform from "./contactform";

export default function UeberUns() {
  const currentPath = window.location.pathname;
  const isEnglish = currentPath.includes("/english");
  return (
    <>
      <div className="Text120">
        {isEnglish ? "More About Us" : "Mehr Über Uns"}
      </div>
      <div className="ContactAndMore">
        <div className="MehrÜberUns">
          <WeeklyChalangeWidget />
          <WerSindWirWidget />
        </div>
        <div className="Text121">
          {isEnglish ? "Get In Touch" : "Kontaktformular"}
        </div>
        <Contactform />
      </div>
    </>
  );
}
