export const Startpage = () => {
  return (
    <>
      <div className="MockUpAndText11">
        <div className="Text11">
          Wir <span className="afterText11">bauen</span>Ihr
          <span className="afterText11"> neues</span>
          <span className="afterText11">Auftreten</span>im Internet
        </div>
        <img
          className="MockUp"
          src="Mac_IPhone_MockUp-min.webp"
          alt="Mac_IPhone_MockUp"
        />
      </div>
      <div className="Farbverlauf">
        <div className="Text12">Firmenphilosophie</div>
        <div className="Text13">
          Unsere Ziel ist es Design und Software
          <span className="afterText13"> in</span> Harmonie zu bringen
        </div>
        <div className="Text14">Unsere Dienstleistungen</div>
        <div className="Text15">
          <ul>
            <li>Web-Hosting</li>
            <li>Web-Design</li>
            <li>Web-Development</li>
            <li>Grafik-Design</li>
          </ul>
        </div>
        <div id="16" className="Text16">
          Warum Oracode?
        </div>
        <div className="Text17">
          Der Name Oracode setzt sich aus "Ora" von
          <span className="afterText17"> Orakel</span>welches Weisheit und
          Wissen
          <span className="afterText17"> symbolisiert</span>und "Code" für
          <span className="afterText17"> Programmieren</span> zusammen
        </div>
        <div className="Text18">Über Uns</div>
        <div className="Text19">
          Wir, die Gründer von
          <span className="afterText19"> Oracode</span>setzen uns das Ziel,
          Design und Software harmonisch zu
          <span className="afterText19"> vereinen.</span> Mit unserer
          Leidenschaft für Technologie entwickeln wir
          <span className="afterText19"> innovative</span>
          Lösungen, die sowohl funktional als auch ästhetisch ansprechend
          <span className="afterText19"> sind.</span>
          Lassen Sie uns gemeinsam Ihre Vision in die Realität umsetzen
        </div>
      </div>
      <div id="120" className="Text120">
        Wir Arbeiten Mit
      </div>
    </>
  );
};
