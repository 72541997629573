export default function WeeklyChalangeWidget() {
  const currentPath = window.location.pathname;
  const isEnglish = currentPath.includes("/english");
  return (
    <a href="/WeeklyChalange">
      <div className="WeeklyChalangeWidget">
        <h1 className="WeeklyChalangeWidgetÜberschrift">Weekly Challenge</h1>
        <p className="WeeklyChalangeWidgetText">
          {isEnglish
            ? [
                "At Oracode we set ourselve every week the challenge",
                <br />,
                " to create a website for a company",
                <br />,
                "thought up by Chat GPT",
              ]
            : [
                "Wir bei Oracode setzen uns jede Woche die",
                <br />,
                " Challenge eine Webseite für eine von Chat GPT",
                <br />,
                "ausgedachte Firma zu erstellen",
              ]}
        </p>
        <p className="WeeklyChalangeWidgetFotter">
          {isEnglish
            ? "Here are all the Weekly Challenge websites"
            : "Hier sind alle Weekly Challenge Webseiten"}
        </p>
      </div>
    </a>
  );
}
