import Navbar from "./navbar";
import { Startpage } from "./StartPage";
import { Footer } from "./footer";
import Logos from "./Logos";
import UeberUns from "./ÜberUns";

export const Home = () => {
  return (
    <>
      <Navbar />
      <Startpage />
      <Logos />
      <UeberUns />
      <Footer />
    </>
  );
};
