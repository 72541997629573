import AddressMapLink from "./AdressMapLink";
export const FooterEnglish = () => {
  return (
    <div id="footer" className="footer">
      <div className="Kontakte">
        <div className="FooterUeberschriften">Contact</div>
        <div className="FooterText">
          <a Style={"color: black;"} href="mailto:info@oracode.de">
            info@oracode.de
          </a>
        </div>
      </div>
      <div className="Impressum">
        <div className="FooterUeberschriften">Imprint</div>
        <div className="FooterText">
          Oracode <br />
          <AddressMapLink address="Behlstraße 2, 65366 Geisenheim" />
        </div>
      </div>
      <div className="Datenschutz">
        <div className="FooterUeberschriften">Data-Protection</div>
        <div className="FooterText">
          <a href="/Datenschutz" Style={"color: black;"}>
            Data-Protection
          </a>
        </div>
      </div>
    </div>
  );
};
