import Navbar from "./navbar";
import { StartPageEnglish } from "./StartPageEnglish";
import Logos from "./Logos";
import { FooterEnglish } from "./footerEnlish";
import UeberUns from "./ÜberUns";

export const HomeEnglish = () => {
  return (
    <>
      <Navbar />
      <StartPageEnglish />
      <Logos />
      <UeberUns />
      <FooterEnglish />
    </>
  );
};
