import { englishText } from "../textdaten";
export const StartPageEnglish = () => {
  function checkId(englishText) {
    return englishText.id >= 12 && englishText.id <= 19;
  }

  const arr = englishText.filter(checkId);

  return (
    <>
      <div className="MockUpAndText11">
        <div className="Text11">
          We built
          <br />
          your new
          <br />
          Appearance
          <br />
          on the Internet
        </div>
        <img
          className="MockUp"
          src="Mac_IPhone_MockUp-min.webp"
          alt="Mac_IPhone_MockUp"
        />
      </div>
      <div className="Farbverlauf">
        {arr.map((x) => (
          <div id={x.id} className={`Text${x.id}`} key={x.id}>
            {x.text}
          </div>
        ))}
      </div>
      <div className="Text120">We work with</div>
    </>
  );
};
