import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import LogIn from "./components/LogIn";
import { Home } from "./components/HomeDeutsch";
import { HomeEnglish } from "./components/HomeEnglish";
import { WeeklyChalangePage } from "./components/WeeklyChalangePage";
import WerSindWirPage from "./components/WerSindWirPage";
import Contactform from "./components/contactform";
import { Datenschutz } from "./components/datenschutz";

const App = () => {
  const [redirectTo, setRedirectTo] = useState(null);

  useEffect(() => {
    const visited = localStorage.getItem("visited");
    if (!visited) {
      const userLanguage = navigator.language;
      if (userLanguage.startsWith("de")) {
        setRedirectTo("/");
      } else {
        setRedirectTo("/english");
      }
      localStorage.setItem("visited", "true");
    }
  }, []);

  useEffect(() => {
    if (redirectTo) {
      window.location.reload(); // Lädt die Seite sofort neu, sobald der Benutzer redirected wurde
    }
  }, [redirectTo]);

  return (
    <BrowserRouter>
      {redirectTo && <Navigate to={redirectTo} />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/LogIn" element={<LogIn />} />
        <Route path="/english" element={<HomeEnglish />} />
        <Route path="/WeeklyChalange" element={<WeeklyChalangePage />} />
        <Route path="/WerSindWir" element={<WerSindWirPage />} />
        <Route path="/Contact" element={<Contactform />} />
        <Route path="/Datenschutz" element={<Datenschutz />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
