import React from "react";

const AddressMapLink = ({ address }) => {
  const handleMapLinkClick = () => {
    const formattedAddress = encodeURIComponent(address);

    if (
      navigator.platform.indexOf("Mac") !== -1 ||
      navigator.platform.indexOf("iPhone") !== -1 ||
      navigator.platform.indexOf("iPad") !== -1 ||
      navigator.platform.indexOf("iPod") !== -1
    ) {
      // Wenn der Benutzer ein Apple-Gerät verwendet, wird die Apple Maps App geöffnet
      window.open(`maps://maps.apple.com/?q=${formattedAddress}`, "_blank");
    } else {
      // Ansonsten wird Google Maps in einem neuen Browserfenster geöffnet
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${formattedAddress}`,
        "_blank"
      );
    }
  };

  return (
    <span
      onClick={handleMapLinkClick}
      style={{ cursor: "pointer", textDecoration: "underline" }}
    >
      65366 Geisenheim
      <br /> Behlstarße 2
    </span>
  );
};

export default AddressMapLink;
